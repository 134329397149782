import { AccessService, CM_UI_MENU_ITEM } from '@cm/ui-modules';
import { FrontendTranslateService } from '@shared/services/translation.service';

export const DEFAULT_MENU = {
    "menuItems": [
      {
        "enabled": true,
        "label": "Messages",
        "description": "Create video interactive messages with SMS and/or email invitations",
        "icon": "chat",
        "access": 'messages',
        "items": [
         {
          "label": "New campaign",
         "link": "/admin/campaign/create",
         "enabled": true,
         "description": 'Create video messages',
         "icon": 'add_circle'
        },
          { "label": "Campaigns",
            "link": "/app/campaigns",
            "enabled": true,
            "description": 'Manage your video messages',
            "icon": 'chat'
           },
          {
            "label": "Message Media library",
             "link": "/admin/mediaassets/project/highp",
             "icon": "photo_library",
             "description": "Upload and manage your media files for messages", "enabled": true
            },
            {
              "label": "Open Web App",
              "link": "/client/starter",
              "icon": "web",
              "description": "Web App for Highp Messages",
              "enabled": true
             },
            {
             "label": "Download App",
             "link": "https://apps.apple.com/pl/app/highp-messages/id1661305107",
             "icon": "phone_iphone",
             "description": "Download Highp Messages App for iPhone, iPad and Macosx (silicon)",
             "enabled": true
            }
        ]
      },
     {
       "enabled": true,
       "label": "Presentation",
       "description": "Create non linear video presentations",
       "icon": "jamboard_kiosk",
       "access": 'presentations',
       "items": [
          { "label": "Presentations", "link": "/admin/presentation", "enabled": true,
          'description': 'Create and manage your non-linear video presentations',
          'icon': 'jamboard_kiosk'
         },
         { "label": "Media library",
            "link": "/admin/mediaassets/project/dbe",
            "icon": "photo_library",
            "description": "Upload and manage your media files for slides", "enabled": true
          },
          { "label": "Remote Meetings",
          "link": "/app/meetings",
          "icon": "videocam",
          "description": "Get access to list of all conducted remote meetings in Highp Presentations",
          "enabled": true ,
          "access": 'meetings'
        },


           {
            "label": "Powerpoint Add-on",
            "link": "https://appsource.microsoft.com/en-sg/product/office/WA104380745?tab=Overview",
            "icon": "imagesmode",
            "description": "Add Highp Pressentation add-on to Powerpoint from AppSource. ",
             "enabled": true },
             {
               "label": "Download App",
               "link": "https://apps.apple.com/pl/app/highp-presentations/id1661483916",
               "icon": "phone_iphone",
               "description": "Download Highp Presentation App for iPhone, iPad and Macosx (silicon)",
                "enabled": true },
       ]
     },

     {
       "enabled": true,
       "label": "Forms",
       "description": "Create forms to collect data, register new customers or acquire consents",
       "icon": "fact_check",
       "access": 'forms',
       "items": [

         {
           "label": "Forms",
           "icon": "fact_check",
            "link": "/admin/agreement/forms" ,
            "description": "Create forms for use in Highp Insights or your web pages", "enabled": true

           },
           {
             "label": "Planner",
             "icon": "calendar_month",
              "link": "/admin/datuso/campaign/list" ,
              "description": "Schedule form availability", "enabled": true
         },
         {
          "label": "Download App",
          "link": "https://apps.apple.com/pl/app/highp-presentations/id1661484887",
          "icon": "phone_iphone",
          "description": "Download Highp Insights App for iPhone, iPad and Macosx (silicon)",
           "enabled": true }
       ]
     },

     {
       "enabled": true,
       "label": "Contacts",
       "description": "Manage your contacts, leads and segments",
       "icon": "perm_contact_calendar",
       "access": 'contacts',
       "items": [
         { "label": "Contacts", "link": "/app/contacts", "enabled": true ,
         "description": "Manage your Customer Data and Relations", "icon": "account_box"
         },
         { "label": "Workplaces", "link": "/app/workplaces" , "enabled": true},
         { "label": "Groups", "link": "/app/groups", "enabled": true },
         { "label": "Specializations", "link": "/app/specializations", "enabled": true },
         { "label": "Custom fields", "link": "/app/custom-fields", "enabled": true },
         { "label": "Leads",
         "description": "Manage data that is gathered from forms",
         "link": "/app/leads", "enabled": true },

         {
           "label": "Segmentation",
           "description": "Create segments to target your messages based on contacts and their behavior",
           "icon": "database",
           "access": 'segments',
           "link": "/app/segments" , "enabled": true}
       ]
     },
     {
       "enabled": true,
       "label": "Automator",
       "icon": "fork_right",
       "access": 'automations',
       "description": "Automate your marketing processes",
       "link": "/admin/automator/workflows"
     },
     {
       "enabled": true,
       "label": "Administration",
       "description": "Manage your user accounts, roles and settings",
       "icon": "settings",
       "access": 'administrations',
       "items": [
        { "label": "Team members", "icon": "badge", "description": "Administrate your team members, privilages and licences", "link": "/admin/user/role", "enabled": true },
        { "label": "Billing", "icon": "credit_card", "access": "billing", "description": "Check your billing status and platform usage", "link": "/app/billing", "enabled": true },
        { "label": "Batch jobs ",  "icon": "schedule","description": "Check your contact imports status","link": "/app/batch-job", "enabled": true },
        { "label": "Other batch jobs", "link": "/admin/batchjobs/list", "enabled": true },

       ]
     },
     {
       "enabled": false,
       "label": "Salesforce Integration",
       "access": 'sfdc',
       "icon": "integration_instructions",
       "description": "Integrate with Salesforce",
       "link": "/admin/sfdc/integration"
     },
     {
      "enabled": false,
      "label": "Education",
      "description": "Create educationprograms and tests",
      "icon": "ballot",
      "access": 'education',
      "items": [
      { "label": "Tests",
        "link": "/admin/challenge/list",
        "description": "Create tests and quizzes",
        "enabled": true
      },
      { "label": "Courses",
        "link": "/admin/courses/list", "enabled": true },
      { "label": "Libraries",
        "link": "/admin/library/list", "enabled": true },
      { "label": "Categories",
        "link": "/admin/courses/list", "enabled": true },
      ]
    },
   ],
 };


 export function menuFactory(translate: FrontendTranslateService, checkAccess: AccessService) {
  return translateAndCheckPrivilege(DEFAULT_MENU,translate,checkAccess);
}

 export const MENU_PROVIDER =
 {
   provide: CM_UI_MENU_ITEM,
   useFactory: menuFactory,
   deps: [FrontendTranslateService, AccessService]
 }

function translateAndCheckPrivilege(menu, translate, checkAccess) {

  return {
    ...menu,
    menuItems: menu.menuItems?.map(item => ({
      ...item,
      label: item.label ? translate.instant(item.label) : '', // replace 'defaultLabel' with a valid key
      description: item.description ? translate.instant(item.description) : '', // replace 'defaultDescription' with a valid key
      enabled: item.access ? checkAccess.checkAccess(item.access) : true ,
      items: item.items?.map(subItem => ({
        ...subItem,
        label: subItem.label ? translate.instant(subItem.label) : '', // replace 'defaultLabel' with a valid key
        description: subItem.description ? translate.instant(subItem.description) : '', // replace 'defaultDescription' with a valid key
        enabled: subItem.access ? checkAccess.checkAccess(subItem.access) : true ,
      })),
    })),
  };
}
